<script setup lang="ts">
  import { useField, useForm } from 'vee-validate'
  import { toTypedSchema } from '@vee-validate/zod'
  import * as zod from 'zod'
  import { useI18n } from 'vue-i18n'

  const emit = defineEmits<{
    close: []
    register: []
    'forgotten-password': []
  }>()

  const { t } = useI18n()

  const validationSchema = toTypedSchema(
    zod.object({
      email: zod
        .string({ required_error: t('Modal.Login.errors.emailEmpty') })
        .email({ message: t('Modal.Login.errors.email') }),
      password: zod.string({ required_error: t('Modal.Login.errors.password') })
    })
  )
  const { handleSubmit, errors } = useForm({ validationSchema })
  const { value: email } = useField('email')
  const { value: password } = useField('password')

  const isSubmitting = ref(false)
  const recaptcha = useRecaptcha()
  const loginFailed = ref(false)
  const { refetch } = useStoreCreator().fetchCreator()
  const trackSection = 'modal-login'

  const onSubmit = handleSubmit(async (values) => {
    useTrack().track(trackSection)
    isSubmitting.value = true
    try {
      const token = await recaptcha.getToken('login')
      const { companyId } = useStoreApp()
      await useStoreAuth().login(companyId, { ...values, token: token as string })
      refetch()
      emit('close')
    } catch (error) {
      loginFailed.value = true
    } finally {
      isSubmitting.value = false
    }
  })

  const buttonSignUp = computed(() => useBrandingButton().extractButton('branding_general', 'signup_btn'))
</script>

<template>
  <ModalApp
    show
    footer-fixed
    @close="emit('close')"
  >
    <h1 class="text-primary-heading-1">{{ $t('Modal.Login.title') }}</h1>
    <form
      class="login-form"
      @submit="onSubmit"
    >
      <FormGroup>
        <FormLabel for="email">{{ $t('Modal.Login.form.email') }} *</FormLabel>
        <FormInputText
          v-model="email"
          class="login-form__input"
          name="email"
          :placeholder="$t('Modal.Login.form.emailExample')"
          :error-message="errors.email"
        />
      </FormGroup>
      <FormGroup>
        <FormLabel for="password">{{ $t('Modal.Login.form.password') }} *</FormLabel>
        <FormInputPassword
          v-model="password"
          class="login-form__input"
          name="password"
          :placeholder="$t('Modal.Login.form.passwordExample')"
          :error-message="errors.password"
        />
      </FormGroup>
      <button
        class="text-secondary-body login-form__forgotten-password"
        type="button"
        @click="emit('forgotten-password')"
      >
        {{ $t('Modal.Login.passwordForgotten') }}
      </button>
      <FormError v-if="loginFailed">
        {{ $t('Modal.Login.errors.login') }}
      </FormError>
    </form>

    <template #footer>
      <ButtonBrandingInverted
        :button-branding="buttonSignUp"
        @click="emit('register')"
      >
        {{ $t('Modal.Login.buttonSignUp') }}
      </ButtonBrandingInverted>
      <ButtonSignIn
        :track-section="trackSection"
        :loading="isSubmitting"
        type="submit"
        @login="onSubmit"
      />
    </template>
  </ModalApp>
</template>

<style scoped>
  .login-form {
    display: flex;
    flex-direction: column;
    gap: 20px;

    &__input {
      width: 100%;
    }

    &__forgotten-password {
      cursor: pointer;
      text-align: left;
      &:hover {
        text-decoration: underline;
      }
    }
  }
</style>
