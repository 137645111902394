<script setup lang="ts">
  import { useField, useForm } from 'vee-validate'
  import { toTypedSchema } from '@vee-validate/zod'
  import * as zod from 'zod'
  import { useI18n } from 'vue-i18n'
  import type { ApiErrorClass } from '~/types/apiError'
  const { t } = useI18n()

  const emit = defineEmits<{
    close: []
    login: []
  }>()

  const isCaseChecked = computed((): boolean => isPasswordCaseChecked(password.value as string))
  const isLengthChecked = computed((): boolean => isPasswordLengthChecked(password.value as string))
  const isNumberLetterChecked = computed((): boolean => isPasswordNumberLetterChecked(password.value as string))
  const isSpecialCharChecked = computed((): boolean => isPasswordSpecialCharChecked(password.value as string))

  const validationSchema = toTypedSchema(
    zod.object({
      email: zod
        .string({ required_error: t('Modal.Register.errors.emailEmpty') })
        .email({ message: t('Modal.Register.errors.email') }),
      password: zod.string().refine((value) => isPasswordValid(value)),
      firstname: zod.string({ required_error: t('Modal.Register.errors.firstname') }),
      cgu: zod
        .boolean({ required_error: t('Modal.Register.errors.cgu') })
        .refine((value) => value, { message: t('Modal.Register.errors.cgu') })
    })
  )
  const { handleSubmit, errors } = useForm({ validationSchema })

  const { value: email } = useField('email')
  const { value: password } = useField('password')
  const { value: firstname } = useField('firstname')
  const { value: cgu } = useField('cgu')

  const isSubmitting = ref(false)
  const recaptcha = useRecaptcha()
  const registerFailedErrors = ref<Array<string>>([])
  const { refetch } = useStoreCreator().fetchCreator()

  const trackSection = 'modal-sign-up'

  const onSubmit = handleSubmit(async (values) => {
    useTrack().track(trackSection)
    isSubmitting.value = true
    registerFailedErrors.value = []
    const token = await recaptcha.getToken('register')
    try {
      const { companyId } = useStoreApp()
      await useStoreAuth().register(companyId, { ...values, token: token as string })
      refetch()
      emit('close')
    } catch (error) {
      handleRegisterFailedMessage(error as ApiErrorClass)
    } finally {
      isSubmitting.value = false
    }
  })

  const handleRegisterFailedMessage = (error: ApiErrorClass) => {
    const { response } = error
    if (response && response._data) {
      for (const value of Object.values(response._data)) {
        const transKey = `ApiErrors.${value}`
        registerFailedErrors.value.push(t(transKey))
      }
    } else {
      registerFailedErrors.value.push(t('ApiErrors.generic'))
    }
  }

  const buttonSignIn = computed(() => useBrandingButton().extractButton('branding_general', 'signin_btn'))
</script>

<template>
  <ModalApp
    show
    footer-fixed
    @close="emit('close')"
  >
    <h1 class="text-primary-heading-1">{{ $t('Modal.Register.title') }}</h1>
    <form
      class="register-form"
      @submit="onSubmit"
    >
      <FormGroup>
        <FormLabel for="email">{{ $t('Modal.Register.form.email') }} *</FormLabel>
        <FormInputText
          v-model="email"
          class="register-form__input"
          name="email"
          :placeholder="$t('Modal.Register.form.emailExample')"
          :error-message="errors.email"
        />
      </FormGroup>
      <FormGroup>
        <FormLabel for="password">{{ $t('Modal.Register.form.password') }} *</FormLabel>
        <FormInputPassword
          v-model="password"
          class="register-form__input"
          name="password"
          :placeholder="$t('Modal.Register.form.passwordExample')"
        />

        <PasswordRulesCheckGrid
          class="register-form__password--checks"
          :is-length-checked="isLengthChecked"
          :is-number-letter-checked="isNumberLetterChecked"
          :is-case-checked="isCaseChecked"
          :is-special-char-checked="isSpecialCharChecked"
        />
      </FormGroup>
      <FormGroup>
        <FormLabel for="firstname">{{ $t('Modal.Register.form.firstname') }} *</FormLabel>
        <FormInputText
          v-model.trim="firstname"
          class="register-form__input"
          name="firstname"
          :placeholder="$t('Modal.Register.form.firstnameExample')"
          :error-message="errors.firstname"
        />
      </FormGroup>
      <FormCheckbox
        v-model="cgu"
        name="cgu"
        class="register-form__cgu"
        :error-message="errors.cgu"
      >
        {{ $t('Modal.Register.form.cgu1') }}
        <a
          target="_blank"
          href="https://assets.loyoly.io/legal/common/cgu.pdf"
        >
          {{ $t('Modal.Register.form.cgu2') }}
        </a>
        {{ $t('Modal.Register.form.cgu3') }}
        <a
          target="_blank"
          href="https://assets.loyoly.io/legal/common/privacy.pdf"
        >
          {{ $t('Modal.Register.form.cgu4') }}
        </a>
      </FormCheckbox>
      <div v-if="registerFailedErrors.length">
        <FormError
          v-for="error in registerFailedErrors"
          :key="error"
        >
          {{ error }}
        </FormError>
      </div>
    </form>

    <template #footer>
      <ButtonBrandingInverted
        :button-branding="buttonSignIn"
        @click="emit('login')"
      >
        {{ $t('Modal.Register.buttonSignIn') }}
      </ButtonBrandingInverted>
      <ButtonSignUp
        :track-section="trackSection"
        :loading="isSubmitting"
        type="submit"
        @click="onSubmit"
      />
    </template>
  </ModalApp>
</template>

<style scoped>
  .register-form {
    display: flex;
    flex-direction: column;
    gap: 20px;

    &__input {
      width: 100%;
    }

    &__password--checks {
      display: grid;
      grid-template-columns: auto auto;
      row-gap: 5px;
      column-gap: 10px;
    }

    &__cgu {
      height: auto;
    }
  }
</style>
