<script setup lang="ts">
  import type { ButtonBranding as ButtonBrandingType } from '~/types/branding'
  import type { ReferralProgram } from '~/types/referral'

  const props = defineProps<{
    referralProgram: ReferralProgram
    isExtensionMode?: boolean
  }>()

  const SectionName = 'FU-Referral'
  const { getRewardFormattedText } = useFormattedText()
  const { isAuthenticated } = storeToRefs(useStoreAuth())
  const isSuccess = ref(false)
  const showModal = ref(false)

  const section = computed(() => {
    const { brandings } = useStoreBranding()

    if (!brandings?.branding_referral) return

    const { extractTypography } = useBrandingTypography()
    const { extractButton } = useBrandingButton()

    const { id, section_color, layout, img_desktop, img_mobile } = brandings.branding_referral

    return {
      id,
      backgroundImgDesktop: img_desktop,
      backgroundImgMobile: img_mobile,
      card: extractBrandingCard(brandings.branding_referral.card),
      contentAlignment: getContentAlignmentResponsive(layout),
      backgroundColor: section_color,
      title: extractTypography('branding_referral', 'title', 'primary'),
      titleSuccess: extractTypography('branding_referral', 'title_success', 'primary'),
      description: extractTypography('branding_referral', 'description', 'secondary'),
      descriptionSuccess: extractTypography('branding_referral', 'description_success', 'secondary'),
      actionBtnSuccess: extractButton('branding_referral', 'action_btn_success'),
      actionBtnDisconnected: extractButton('branding_referral', 'action_btn_disconnected') as ButtonBrandingType
    }
  })

  // login or register
  const showModalLogin = ref(false)
  const showModalRegister = ref(false)
  const showModalForgottenPassword = ref(false)
  const showModalForgottenPasswordSuccess = ref(false)
  const requestNewPasswordEmail = ref('')

  const onLoginFromModal = () => {
    showModalRegister.value = false
    showModalLogin.value = true
  }

  const onRegisterFromModal = () => {
    showModalRegister.value = true
    showModalLogin.value = false
  }

  const onForgottenPasswordFromModal = () => {
    showModalLogin.value = false
    showModalRegister.value = false
    showModalForgottenPassword.value = true
  }

  const onSuccessPasswordForgotten = (email: string) => {
    showModalForgottenPassword.value = false
    requestNewPasswordEmail.value = email
    showModalForgottenPasswordSuccess.value = true
  }

  const referralTitle = computed(() => {
    if (!section.value) return
    if (isSuccess.value) return section.value.titleSuccess.text
    else {
      let rewardText = getRewardFormattedText(
        section.value.title.text,
        props.referralProgram.godson_reward.amount,
        'local_currency_referral_friend'
      )
      rewardText = getRewardFormattedText(
        rewardText,
        props.referralProgram.godfather_reward.amount,
        'local_currency_reward_customer'
      )
      return rewardText
    }
  })
</script>

<template>
  <SectionAnchored anchor-id="referral">
    <SectionReferral
      v-if="section"
      id="referral"
      class="referral-wrapper"
      :section-card-disposition="section.contentAlignment"
      :background-color="section.backgroundColor"
      :background-image-desktop-url="section.backgroundImgDesktop"
      :background-image-mobile-url="section.backgroundImgMobile"
      :border-radius="section.card.borderRadius"
      :border-color="section.card.borderColor"
      :hover-background-color="section.card.hoverBackgroundColor"
      :section-card="section.card"
      :section-title="section.title"
      :section-description="section.description"
    >
      <template #title>{{ referralTitle }}</template>
      <template #description>
        {{ isSuccess ? section.descriptionSuccess.text : section.description.text }}
      </template>
      <div class="referral-wrapper__content">
        <LoginOrRegister
          v-if="!isAuthenticated"
          :track-section="SectionName"
          :show-register-btn="false"
          :sign-in-branding="section.actionBtnDisconnected"
          @login="showModalLogin = true"
          @register="showModalRegister = true"
        />
        <SectionReferralTabs
          v-else-if="!isSuccess"
          :is-extension-mode="isExtensionMode"
          @success="isSuccess = true"
        />
        <ButtonBranding
          v-else
          class="referral-form--restart"
          :button-branding="section.actionBtnSuccess"
          @click="isSuccess = false"
        >
          {{ section.actionBtnSuccess.text }}
        </ButtonBranding>

        <a
          v-if="isAuthenticated"
          class="referral-wrapper--link text-secondary-heading-2"
          @click="showModal = true"
        >
          {{ $t('SectionReferral.seeReferral') }}
        </a>
        <SectionReferralModal
          v-if="showModal"
          :is-extension-mode="isExtensionMode"
          @close="showModal = false"
        />
        <template v-if="!isAuthenticated">
          <ModalLogin
            v-if="showModalLogin"
            @close="showModalLogin = false"
            @register="onRegisterFromModal"
            @forgotten-password="onForgottenPasswordFromModal"
          />
          <ModalRegister
            v-if="showModalRegister"
            @close="showModalRegister = false"
            @login="onLoginFromModal"
          />
          <ModalPasswordForgotten
            v-if="showModalForgottenPassword"
            @close="showModalForgottenPassword = false"
            @success="onSuccessPasswordForgotten"
          />
          <ModalPasswordForgottenSuccess
            v-if="showModalForgottenPasswordSuccess"
            :email="requestNewPasswordEmail"
            @close="showModalForgottenPasswordSuccess = false"
          />
        </template>
      </div>
    </SectionReferral>
  </SectionAnchored>
</template>

<style scoped>
  .referral-wrapper {
    display: flex;
    aspect-ratio: auto;

    @media (min-width: 768px) {
      aspect-ratio: 2.02 / 1;
    }

    &__content {
      display: flex;
      flex-direction: column;
      gap: 10px;
      @media (min-width: 768px) {
        gap: 20px;
      }
    }

    &--link {
      text-decoration: underline;
      text-align: center;
      display: block;
      cursor: pointer;
      color: v-bind(section.description.color);
    }
  }
</style>
