<script setup lang="ts">
  const { offset } = useRoute().query

  const offsetValue = `-${offset}px`
  defineProps<{
    anchorId: string
    anchorIdModal?: string
  }>()
</script>

<template>
  <div>
    <a
      :id="anchorId"
      class="anchor"
    />
    <a
      v-if="anchorIdModal"
      :id="anchorIdModal"
      class="anchor"
    />
    <slot />
  </div>
</template>
<style scoped>
  .anchor {
    position: absolute;
    margin-top: v-bind(offsetValue);
  }
</style>
