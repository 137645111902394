<script setup lang="ts">
  import type { Referral } from '~/types/referral'

  defineProps<{
    isExtensionMode?: boolean
  }>()

  const emit = defineEmits<{
    close: []
  }>()
  const { t, d } = useI18n()
  const { extractButton } = useBrandingButton()
  const branding = extractButton('branding_referral', 'action_btn')
  const { getMoneyFormattedText } = useFormattedText()

  const { companyId } = useRoute().params
  const { data: referrals } = useStoreReferral().getReferrals(parseInt(companyId as string))

  onMounted(() => {
    useTrack().track('modal-referral-list')
  })

  const referralStatus = (referral: Referral) => {
    if (referral.status === 1) {
      return t('SectionReferral.Modal.statusPending')
    } else if (referral.status === 2) {
      if (referral.godfather_amount && referral.godfather_amount > 0) {
        return t('SectionReferral.Modal.statusUsed')
      } else if (new Date(referral.godfather_valid_until as string) > new Date()) {
        return t('SectionReferral.Modal.statusAvailable')
      } else {
        return t('SectionReferral.Modal.statusExpired')
      }
    }
    return t('SectionReferral.Modal.statusInvalidate')
  }

  const referralAmount = (referral: Referral) => {
    if (referral.godfather_reward_type === 1) {
      return referral.godfather_reward_amount
    } else if (referral.godfather_reward_type === 2) {
      return `${referral.godfather_reward_amount}%`
    }
    return getMoneyFormattedText(referral.godfather_reward_amount)
  }

  const referralPromocode = (referral: Referral) => {
    if (referral.status === 1) {
      return t('SectionReferral.Modal.promocodeComing')
    } else if (referral.status === 3) {
      return t('SectionReferral.Modal.promocodeUnavailable')
    }
    return referral.godfather_promocode
  }

  const referralPoint = (referral: Referral) => {
    if (referral.status === 1) {
      return t('SectionReferral.Modal.promocodeComing')
    } else if (referral.status === 3) {
      return t('SectionReferral.Modal.promocodeUnavailable')
    }
    return t('SectionReferral.Modal.points_given')
  }

  const referralExpiration = (referral: Referral) => {
    if (referral.status === 1) {
      return t('SectionReferral.Modal.promocodeComing')
    } else if (referral.status === 3) {
      return t('SectionReferral.Modal.promocodeUnavailable')
    }

    return d(new Date(referral.godfather_valid_until as string), 'short')
  }
</script>

<template>
  <ModalApp
    :is-extension-mode="isExtensionMode"
    show
    no-footer
    @close="emit('close')"
  >
    <h1 class="text-primary-heading-1">{{ $t('SectionReferral.Modal.title') }}</h1>

    <div
      v-for="referral in referrals"
      :key="referral.id"
      class="referral-item"
    >
      <div class="referral-item__title text-primary-heading-1">
        {{ referralAmount(referral) }} {{ t(`SectionReferral.Modal.referral_type_${referral.godfather_reward_type}`) }}
        <div v-if="referral.godfather_reward_type === 1">{{ referralPoint(referral) }}</div>
      </div>
      <div class="referral-item__content">
        <ButtonCopy
          v-if="referralStatus(referral) === t('SectionReferral.Modal.statusAvailable')"
          class="text-primary-heading-2"
          :radius="branding.radius"
          :background-color="branding.textColor"
          :hover-background-color="branding.hoverTextColor"
          :font-size="branding.fontSize"
          :text-color="branding.backgroundColor"
          :hover-text-color="branding.hoverBackgroundColor"
          :weight="branding.weight"
          :copy-text="referralPromocode(referral)"
          :copied-text="$t('SectionRewardsClaimModal.copied')"
          :content-to-copy="referralPromocode(referral)"
        />
        <ButtonBrandingInverted
          v-else
          :button-branding="branding"
          disabled
        >
          {{ referralPromocode(referral) }}
        </ButtonBrandingInverted>
        <div class="referral-item__table">
          <div class="referral-item__table-item">
            <div>{{ $t('SectionReferral.Modal.status') }}</div>
            <div>{{ referralStatus(referral) }}</div>
          </div>
          <div class="referral-item__table-item">
            <div>{{ $t('SectionReferral.Modal.email') }}</div>
            <div class="referral-item__table-email">{{ referral.godson_email }}</div>
          </div>
          <div class="referral-item__table-item">
            <div>{{ $t('SectionReferral.Modal.date') }}</div>
            <div>{{ referralExpiration(referral) }}</div>
          </div>
        </div>
      </div>
    </div>
    <SectionEmpty v-if="!referrals || referrals.length === 0">
      {{ $t('SectionReferral.Modal.emptyState') }}
    </SectionEmpty>
  </ModalApp>
</template>

<style scoped>
  .referral-item {
    border: 1px solid var(--gray-400);
    background: #fff;
    margin-top: 10px;

    &__title {
      display: flex;
      height: 50px;
      padding: 10px 20px;
      gap: 10px;
      align-self: stretch;
      background-color: var(--gray-100);
      line-height: 30px;
    }

    &__content {
      padding: 10px 20px;
    }

    &__table {
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      font-size: 17.5px;
      font-weight: 500;
      margin-bottom: 10px;
      gap: 10px;

      @media (min-width: 768px) {
        flex-direction: row;
      }

      &-item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 5px;

        :first-child {
          font-weight: 400;
        }
      }

      &-email {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        @media (min-width: 768px) {
          max-width: calc(0.4 * var(--breakpoint-width));
        }
      }
    }
  }
</style>
