<script setup lang="ts">
  defineProps<{
    email: string
  }>()

  const emit = defineEmits<{
    close: []
  }>()

  onMounted(() => {
    useTrack().track('modal-forgot-password-2')
  })
</script>

<template>
  <ModalApp
    show
    footer-fixed
    @close="emit('close')"
  >
    <h1 class="text-primary-heading-1">{{ $t('Modal.PasswordForgottenSuccess.title') }}</h1>

    <div class="password-forgotten__detail">
      <span class="text-primary-heading-2">{{ $t('Modal.PasswordForgottenSuccess.subtitle') }}</span>
      <p class="text-secondary-heading-2">
        {{ $t('Modal.PasswordForgottenSuccess.message') }}
        <span class="bold">{{ email }}</span>
      </p>
    </div>
  </ModalApp>
</template>

<style scoped>
  .password-forgotten__detail {
    display: grid;
    gap: 20px;
  }
</style>
