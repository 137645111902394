<script setup lang="ts">
  import { useField, useForm } from 'vee-validate'
  import { toTypedSchema } from '@vee-validate/zod'
  import * as zod from 'zod'
  import { useI18n } from 'vue-i18n'

  const emit = defineEmits<{
    close: []
    success: [email: string]
  }>()

  const buttonSubmit = computed(() => useBrandingButton().extractButton('branding_general', 'signin_btn'))

  const { t } = useI18n()

  const validationSchema = toTypedSchema(
    zod.object({
      email: zod
        .string({ required_error: t('Modal.PasswordForgotten.errors.emailEmpty') })
        .email({ message: t('Modal.PasswordForgotten.errors.email') })
    })
  )
  const { handleSubmit, errors } = useForm({ validationSchema })
  const { value: email } = useField('email')

  const isSubmitting = ref(false)
  const submissionFailedMessage = ref()

  const onSubmit = handleSubmit(async ({ email }) => {
    useTrack().track('click-password1')
    isSubmitting.value = true
    const companyId = useRoute().params.companyId as string
    try {
      await useStoreAuth().requestNewPassword(parseInt(companyId, 10), email)
      emit('success', email)
    } catch (error) {
      submissionFailedMessage.value = t('Modal.PasswordForgotten.errors.emailUnknown')
    } finally {
      isSubmitting.value = false
    }
  })

  onMounted(() => {
    useTrack().track('modal-forgot-password')
  })
</script>

<template>
  <ModalApp
    show
    footer-fixed
    @close="emit('close')"
  >
    <h1 class="text-primary-heading-1">{{ $t('Modal.PasswordForgotten.title') }}</h1>
    <form
      class="password-forgotten__form"
      @submit="onSubmit"
    >
      <FormGroup>
        <FormLabel for="email">{{ $t('Modal.PasswordForgotten.form.email') }} *</FormLabel>
        <FormInputText
          v-model="email"
          class="password-forgotten__form--input"
          name="email"
          :placeholder="$t('Modal.PasswordForgotten.form.emailExample')"
          :error-message="errors.email || submissionFailedMessage"
        />
      </FormGroup>
    </form>

    <div class="password-forgotten__information">
      <span class="text-primary-heading-2">{{ $t('Modal.PasswordForgotten.information.title') }}</span>
      <p class="text-secondary-heading-2">{{ $t('Modal.PasswordForgotten.information.text') }}</p>
    </div>

    <template #footer>
      <ButtonBranding
        :button-branding="buttonSubmit"
        type="submit"
        :loading="isSubmitting"
        @click="onSubmit"
      >
        {{ $t('Modal.PasswordForgotten.buttonSubmit') }}
      </ButtonBranding>
    </template>
  </ModalApp>
</template>

<style scoped>
  .password-forgotten {
    &__form {
      display: flex;
      flex-direction: column;
      gap: 20px;

      &--input {
        width: 100%;
      }
    }
    &__information {
      display: grid;
      gap: 20px;
    }
  }
</style>
