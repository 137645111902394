<script setup lang="ts">
  import type { ButtonBranding } from '~/types/branding'

  const props = withDefaults(
    defineProps<{
      trackSection: string
      showRegisterBtn?: boolean
      signInBranding?: ButtonBranding
      handleModals?: boolean
    }>(),
    {
      handleModals: false,
      showRegisterBtn: true,
      signInBranding: undefined
    }
  )

  const showModalLogin = ref(false)
  const showModalRegister = ref(false)
  const showModalForgottenPassword = ref(false)
  const showModalForgottenPasswordSuccess = ref(false)
  const requestNewPasswordEmail = ref('')

  const onLoginFromModal = () => {
    showModalRegister.value = false
    showModalLogin.value = true
  }

  const onRegisterFromModal = () => {
    showModalRegister.value = true
    showModalLogin.value = false
  }

  const onForgottenPasswordFromModal = () => {
    showModalLogin.value = false
    showModalRegister.value = false
    showModalForgottenPassword.value = true
  }

  const onSuccessPasswordForgotten = (email: string) => {
    showModalForgottenPassword.value = false
    requestNewPasswordEmail.value = email
    showModalForgottenPasswordSuccess.value = true
  }

  const emit = defineEmits<{
    login: []
    register: []
  }>()

  const onLogin = () => {
    if (props.handleModals) {
      showModalLogin.value = true
    } else {
      emit('login')
    }
  }

  const onRegister = () => {
    if (props.handleModals) {
      showModalRegister.value = true
    } else {
      emit('register')
    }
  }
</script>

<template>
  <ButtonSignIn
    :track-section="trackSection"
    :branding="signInBranding"
    @login="onLogin"
  />
  <ButtonSignUp
    v-if="showRegisterBtn"
    :track-section="trackSection"
    @register="onRegister"
  />
  <template v-if="handleModals">
    <ModalLogin
      v-if="showModalLogin"
      @close="showModalLogin = false"
      @register="onRegisterFromModal"
      @forgotten-password="onForgottenPasswordFromModal"
    />
    <ModalRegister
      v-if="showModalRegister"
      @close="showModalRegister = false"
      @login="onLoginFromModal"
    />
    <ModalPasswordForgotten
      v-if="showModalForgottenPassword"
      @close="showModalForgottenPassword = false"
      @success="onSuccessPasswordForgotten"
    />
    <ModalPasswordForgottenSuccess
      v-if="showModalForgottenPasswordSuccess"
      :email="requestNewPasswordEmail"
      @close="showModalForgottenPasswordSuccess = false"
    />
  </template>
</template>
