import { useReCaptcha } from 'vue-recaptcha-v3'

export function useRecaptcha() {
  const recaptchaInstance = useReCaptcha()

  const getToken = async (recaptchaAction: string) => {
    await recaptchaInstance?.recaptchaLoaded()
    const token = await recaptchaInstance?.executeRecaptcha(recaptchaAction)
    return token
  }

  return { getToken }
}
